import React, { useState, useContext } from "react";
import { XLg, List } from "react-bootstrap-icons";
import {
  header, top, body,
  head, menu, opened,
  logo, cartIcon, closeIcon,
  left, right,
  tabContent, active, mobileHeader, login, contact
}  from "../style.mod.scss";
import MobileBody from "./body";
import GlobalContext from "services/global-context";
import CartIcon from "assets/svgs/cart-icon.svg";
import Link from "components/link";

const Header = ({ headerData, socialData }) => {
  const { toggleCart, currentCustomer, setCurrentPanel, toggleBodyOverlay } = useContext(GlobalContext);
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(null);

  const openClass = () => open ? opened : "";
  const activeTabClass = (tab) => activeTab === tab ? active : "";
  const toggleMenu = (isOpen = false) => {
    setOpen(isOpen);
    toggleBodyOverlay(isOpen);
    !isOpen && setActiveTab(null);
  };

  const CloseIcon = (props) => (
    <a
      className={`${closeIcon} ${openClass()}`}
      onClick={props.onClick}>
      { open ? <XLg /> : <List /> }
    </a>
  );

  const isDirect = (lnk) => lnk._type === "directLink";

  const handleLogin = () => {
    setCurrentPanel("login");
  };

  const MobileMenu = (props) => (
    <div className={props.className} onBlur={toggleMenu}>
      {
        headerData?.link?.map((tab) => (
          <Link
            key={tab._key}
            to={isDirect(tab) ? tab.url : "#"}
            className={`${activeTabClass(tab._key)}`}
          >
            {tab.title}
          </Link>
        ))
      }
    </div>
  );

  return (
    <div className={`${header} ${mobileHeader}`}>
      <div className={head}>
        <div className={top}>
          <div className={left}>
            <CloseIcon onClick={() => toggleMenu(!open)} />
            <Link to="/"> <img src="//cdn.shopify.com/s/files/1/0224/0210/5416/files/logo_Olav_180x.png" className={logo} /></Link>
          </div>
          <div className={right}>
            {currentCustomer === null && <button className={login} onClick={handleLogin}>Login</button>}
            {currentCustomer !== null && <Link className={login} to="/account">Account</Link>}


            <Link to="/kontakt" className={contact}>Kontakt</Link>
            <a onClick={toggleCart} className={cartIcon}><CartIcon /></a>
          </div>
        </div>
        <MobileMenu className={menu} />
      </div>
      <div className={`${body} ${openClass()}`}>
        <div className={tabContent}>
          <MobileBody headerData={headerData} socialData={socialData} />
          <CloseIcon onClick={() => toggleMenu(false)}/>
        </div>
      </div>
    </div>
  );
};

export default Header;
