import React, { useContext } from "react";
import { loadingBar, progress, visible } from "./style.mod.scss";
import GlobalContext from "services/global-context";

const LoadingBar = () => {
  const { loadingVisible } = useContext(GlobalContext);

  const isVisible = loadingVisible ? visible : "";

  return (
    <div className={`${loadingBar} ${isVisible}`}>
      <div className={progress} />
    </div>
  );
};

export default LoadingBar;
