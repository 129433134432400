// extracted by mini-css-extract-plugin
export var headerBodyWrapper = "body-mod--header-body-wrapper--2enNQ";
export var headerImageWrapper = "body-mod--header-image-wrapper--3ive1";
export var headerLink = "body-mod--header-link--283Vc";
export var headerImage = "body-mod--header-image--1wVMv";
export var headerDescription = "body-mod--header-description--2VTot";
export var headerText = "body-mod--header-text--ovklB";
export var headerTextIcon = "body-mod--header-text-icon--1o3Gm";
export var combinedLinks = "body-mod--combined-links--16TVY";
export var textLinks = "body-mod--text-links--mwOuW";
export var linkGroupWrapper = "body-mod--link-group-wrapper--2A1N_";
export var linkGroup = "body-mod--link-group--McgXS";
export var link = "body-mod--link--fejSt";
export var optImageWrapper = "body-mod--opt-image-wrapper--26BKJ";
export var optImage = "body-mod--opt-image--EMq9C";
export var optDesc = "body-mod--opt-desc--1lrCy";
export var optText = "body-mod--opt-text--1KIDd";
export var optIcon = "body-mod--opt-icon--1zcLC";