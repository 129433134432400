
const localStorageKey = "olav-customer-access-token";

const getAccessToken = () => {
  if (window.localStorage[localStorageKey]) {
    const token = JSON.parse(window.localStorage[localStorageKey]);

    if (new Date() < new Date(token.expiresAt)) {
      return token.accessToken;
    }
  }

  return null;
};

const shopifyQuery = (query, variables) => {
  return fetch(`https://${process.env.GATSBY_SHOPIFY_DOMAIN}/api/2021-07/graphql.json`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Shopify-Storefront-Access-Token": process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  })
    .then((res) => res.json())
    .catch((res) => console.error(res));
};

export const withAccessToken = (callback) => {
  const token = getAccessToken();

  if (token === null) return new Promise((resolve) => resolve(null));

  return callback(token);
};

export const createCustomer = ({ firstName, lastName, email, password }) => {
  return shopifyQuery(`
      mutation customerCreate($input: CustomerCreateInput!) {
        customerCreate(input: $input) {
          customer {
            id
          }
          customerUserErrors {
            code
            field
            message
          }
        }
      }
    `, {
    input: {
      email,
      firstName,
      lastName,
      password
    }
  });
};

export const updateCustomer = (customer) => {
  return withAccessToken((token) => {
    return shopifyQuery(`
    mutation customerUpdate($customerAccessToken: String!, $customer: CustomerUpdateInput!) {
      customerUpdate(customerAccessToken: $customerAccessToken, customer: $customer) {
        customer {
          id
        }
        customerAccessToken {
          accessToken
          expiresAt
        }
        customerUserErrors {
          code
          field
          message
        }
      }
    }
  `, {
      customerAccessToken: token, customer
    });
  }).then((response) => {
    if (response.data?.customerUpdate?.customerAccessToken) {
      setCustomerAccessToken(response.data?.customerUpdate?.customerAccessToken);
    }

    return response;
  });
};

export const createCustomerAccessToken = ({ email, password }) => {
  return shopifyQuery(`
      mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
        customerAccessTokenCreate(input: $input) {
          customerAccessToken {
            accessToken
            expiresAt
          }
          customerUserErrors {
            code
            field
            message
          }
        }
      }
    `, {
    input: {
      email, password
    }
  });
};

export const associateCheckout = (checkoutId) => {
  return withAccessToken((token) => {
    const storageKey = `${checkoutId}${token}`;
    if (sessionStorage.getItem(storageKey)) return;

    shopifyQuery(`
      mutation checkoutCustomerAssociateV2($checkoutId: ID!, $customerAccessToken: String!) {
        checkoutCustomerAssociateV2(
          checkoutId: $checkoutId
          customerAccessToken: $customerAccessToken
        ) {
          checkout {
            id
          }
          checkoutUserErrors {
            code
            field
            message
          }
          customer {
            id
          }
        }
      }
    `,
    {
      checkoutId,
      customerAccessToken: token
    }).then((response) => {
      if (response.data?.checkoutCustomerAssociateV2?.checkoutUserErrors?.length === 0) {
        sessionStorage.setItem(storageKey, true);
      }
    });
  });
};

export const setCustomerAccessToken = (token) => {
  window.localStorage.setItem(localStorageKey, JSON.stringify(token));
};

export const getCustomer = () =>
  withAccessToken((token) =>
    shopifyQuery(`
      {
        customer(customerAccessToken: "${token}") {
          displayName
          firstName
          email
          id
          lastName
        }
      }
    `
    )
  );

export const getPersonalInformation = () =>
  withAccessToken((token) =>
    shopifyQuery(`
    {
      customer(customerAccessToken: "${token}") {
        addresses(first: 1) {
          edges {
            node {
              id
              address1
              address2
              city
              country
              name
              province
              zip
            }
          }
        }
        acceptsMarketing
        displayName
        email
        firstName
        id
        lastName
        phone
        updatedAt
      }
    }
    `)
  );

export const getOrders = () => withAccessToken((token) => shopifyQuery(`
  {
    customer(customerAccessToken: "${token}") {
      orders(first: 100, reverse: true) {
        edges {
          node {
            id
            orderNumber
            processedAt
            fulfillmentStatus
            totalPriceV2 {
              amount
              currencyCode
            }
            totalShippingPriceV2 {
              amount
              currencyCode
            }
            totalTaxV2 {
              amount
              currencyCode
            }
            totalRefundedV2 {
              amount
              currencyCode
            }
            subtotalPriceV2 {
              amount
              currencyCode
            }
            lineItems(first: 100) {
              edges {
                node {
                  quantity
                  discountedTotalPrice {
                    amount
                    currencyCode
                  }
                  originalTotalPrice {
                    amount
                    currencyCode
                  }
                  variant {
                    id
                    sku
                    image {
                      altText
                      originalSrc
                      src
                    }
                    title
                    priceV2 {
                      amount
                      currencyCode
                    }
                  }
                }
              }
            }
            successfulFulfillments(first: 10) {
              fulfillmentLineItems {
                edges {
                  node {
                    lineItem {
                      title
                      quantity
                      currentQuantity
                    }
                  }
                }
              }
              trackingInfo(first: 10) {
                number
                url
              }
              trackingCompany
            }
          }
        }
      }
    }
  }
`));


export const logout = () => window.localStorage.removeItem(localStorageKey);
