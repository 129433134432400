import React from "react";
// import { useState, useContext, useEffect } from "react";
import {
  headerBodyWrapper, headerLink, headerImage,
  headerDescription, headerTextIcon, headerText,
  link, textLinks, linkGroupWrapper, linkGroup,
  headerImageWrapper, combinedLinks, optImageWrapper,
  optImage, optDesc, optText, optIcon,
}  from "./body.mod.scss";
import Image from "components/image";
import Link from "components/link";


// Image Links Component
const ImageLinks = ({ data }) => {
  return (
    <div className={headerImageWrapper}>
      {
        data.links.map((linkData) => (
          <Link className={headerLink} key={linkData._key} to={linkData.url}>
            <Image
              className={headerImage}
              image={linkData.linkImage}
            />
            <div className={headerDescription}>
              <span className={headerText}>{linkData.title}</span>
              <span className={headerTextIcon} />
            </div>
          </Link>
        ))
      }
    </div>
  );
};

// Combined Image + Links Component
const CombinedLinks = ({ data }) => {
  const Links = data.links.map((lnkGroup) => {
    const groupLinks = lnkGroup.links.map((lnk) => (<Link key={lnk._key} className={link} to={lnk.url}>{lnk.title}</Link>));
    return (
      <div key={lnkGroup._key} className={linkGroupWrapper}>
        <span className={linkGroup}>{lnkGroup.title}</span>
        { groupLinks }
      </div>
    );
  });

  const OptionalImage = () => (
    <Link className={optImageWrapper} to={data.optionalImageUrl}>
      <Image
        className={optImage}
        image={data.optionalLinkImage}
      />
      <div className={optDesc}>
        <span className={optText}>{data.optionalTitle}</span>
        <span className={optIcon} />
      </div>
    </Link>
  );

  return (
    <div className={combinedLinks}>
      <div className={textLinks}>
        { Links }
      </div>
      <OptionalImage />
    </div>
  );
};

// Only Text Links Component
const TextLinks = ({ data }) => {
  const Links = data.links.map((lnkGroup) => {
    const groupLinks = lnkGroup.links.map((lnk) => (<Link key={lnk._key} className={link} to={lnk.url}>{lnk.title}</Link>));
    return (
      <div key={lnkGroup._key} className={linkGroupWrapper}>
        <span className={linkGroup}>{lnkGroup.title}</span>
        { groupLinks }
      </div>
    );
  });

  return (
    <div className={textLinks}>
      { Links }
    </div>
  );
};

const HeaderBody = ({ data }) => {
  if (!data || !data._type) return <></>;

  const linkTemplateType = () => {
    if (data._type === "imageLink") return <ImageLinks data={data} />;
    else if (data._type === "combinedLink") return <CombinedLinks data={data} />;
    else if (data._type === "textLink") return <TextLinks data={data} />;
  };

  return (
    <div className={headerBodyWrapper}>
      { linkTemplateType() }
    </div>
  );
};

export default HeaderBody;
