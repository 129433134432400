import { ObjectModel, ArrayModel } from "objectmodel";
import CartItemModel from "./cart-item-model";

const Cart = new ObjectModel({
  items: ArrayModel(CartItemModel),
  createdAt: Date,
  webUrl: String,
  totalPriceV2: new ObjectModel({
    amount: String,
    currencyCode: String,
  }),
  discount: [new ObjectModel({
    applicable: [Boolean],
    code: [String],
    perc: [Number],
  })],
  hasDiscounts: Boolean,
  discountedAmount: Number,
});

export default Cart;
