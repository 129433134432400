import React, { useContext, useState, useEffect } from "react";
import SidePanel from "../side-panel";
import * as styles from "./styles.mod.scss";
import getLoc from "locales";
import GlobalContext from "services/global-context";
import { Formik } from "formik";
import TextInput from "../text-input";
import * as Yup from "yup";
import { createCustomer } from "services/shopify-account";


const RegisterSchema = Yup.object().shape({
  first_name: Yup.string()
    .required(),
  last_name: Yup.string()
    .required(),
  email: Yup.string().email("Invalid email").required(),
  password: Yup.string()
    .required(),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")

});

const Register = ({ wasOpened }) => {
  const [opened, setOpened] = useState(false);
  const [closing, setClosing] = useState(false);
  const { locale, setCurrentPanel } = useContext(GlobalContext);
  const loc = getLoc()[locale].register;
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const handleSubmit = ({ first_name, last_name, email, password }, { setFieldError }) => {
    console.log("is valid", first_name, last_name, email, password);
    setSubmitDisabled(true);

    createCustomer({
      firstName: first_name, lastName: last_name, email, password
    }).then((response) => {
      if (response.data.customerCreate?.customerUserErrors) {
        const exists = response.data.customerCreate?.customerUserErrors.find(k => k.code === "TAKEN");

        if (exists) {
          setFieldError("email", exists.message);
        }
      } else if (response.errors) {
        console.error(response.errors.map((err) => err.message));
      }

      setSubmitDisabled(false);
    });
  };

  const handleLogin = () => {
    setCurrentPanel("login");
    return false;
  };

  useEffect(() => {
    setTimeout(() => !closing && setOpened(true), 0);
  });

  const handleClose = () => {
    setClosing(true);
    setOpened(false);
    setTimeout(() => setCurrentPanel(null), 250);
  };

  const isOpen = () => opened || (!closing && wasOpened);

  return <SidePanel
    open={true}
    bodyClass={styles.body}
    className={`${styles.sidePanel} ${isOpen() ? styles.opened : ""}`}
    onClose={handleClose}
  >
    <h3>{loc.title}</h3>
    <p>{loc.subtitle}</p>

    <Formik
      initialValues={{
        first_name: "", last_name: "", email: "", password: "", confirm_password: ""
      }}
      onSubmit={handleSubmit}
      validationSchema={RegisterSchema}
    >
      {
        ({ errors, touched, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className={styles.formInner}>
              <div className="row">
                <div className="col-12">
                  <TextInput type="text" name="first_name" onChange={handleChange} placeholder={loc.first_name} errors={errors.first_name} touched={touched.first_name} />
                </div>
                <div className="col-12">
                  <TextInput type="text" name="last_name" onChange={handleChange} placeholder={loc.last_name} errors={errors.last_name} touched={touched.last_name} />
                </div>
              </div>
              <h4 className={styles.loginInfo}>Anmeldeinformationen</h4>
              <div className="row">
                <div className="col-12">
                  <TextInput type="text" name="email" onChange={handleChange} placeholder={loc.email}  errors={errors.email} touched={touched.email} />
                </div>
                <div className="col-6">
                  <TextInput type="password" name="password" onChange={handleChange} placeholder={loc.password} errors={errors.password} touched={touched.password} />
                </div>
                <div className="col-6">
                  <TextInput type="password" name="confirm_password" onChange={handleChange} placeholder={loc.confirm_password} errors={errors.confirm_password} touched={touched.confirm_password} />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p className={styles.links}>
                    {loc.haveAccount} <a href="#" onClick={handleLogin}>{loc.login}</a>
                  </p>
                </div>
              </div>
            </div>
            <button type="submit" disabled={submitDisabled} className={styles.button}>{loc.registerNow}</button>
          </form>
        )
      }

    </Formik>
  </SidePanel>;
};

export default Register;
