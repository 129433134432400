// extracted by mini-css-extract-plugin
export var cart = "style-mod--cart--31ZMu";
export var opened = "style-mod--opened--2zby_";
export var closeIcon = "style-mod--close-icon--35BVx";
export var header = "style-mod--header--cQzQQ";
export var title = "style-mod--title--1gz8r";
export var empty = "style-mod--empty--PkzdY";
export var body = "style-mod--body--2tCm2";
export var subtitle = "style-mod--subtitle--p7KLF";
export var emptyImage = "style-mod--empty-image--sWyHq";
export var checkoutButton = "style-mod--checkout-button--3Vk8i";
export var cartInfo = "style-mod--cart-info--3eA4I";
export var items = "style-mod--items--1Na-3";
export var item = "style-mod--item--1e8yK";
export var image = "style-mod--image--1ne5B";
export var removeIcon = "style-mod--remove-icon--pvHes";
export var description = "style-mod--description--198CP";
export var name = "style-mod--name--3AG_N";
export var details = "style-mod--details--30q72";
export var price = "style-mod--price--2HuzK";
export var increment = "style-mod--increment--1tzXQ";
export var val = "style-mod--val--1o4Ri";
export var dec = "style-mod--dec--1lsGd";
export var inc = "style-mod--inc--2iPpe";
export var sum = "style-mod--sum--2xtj2";
export var shipping = "style-mod--shipping--UdIDy";
export var discount = "style-mod--discount--3UR2I";
export var discountTotal = "style-mod--discount-total--3XzZu";
export var discountWrapper = "style-mod--discount-wrapper--2D0-B";
export var discountInput = "style-mod--discount-input--3Z27-";
export var discountLoader = "style-mod--discount-loader--1brPM";
export var loaderKeyFrames = "style-mod--loader-key-frames--3D0pq";
export var discountBtn = "style-mod--discount-btn--22wJA";
export var total = "style-mod--total--1ArXx";
export var recommendations = "style-mod--recommendations--GwVx7";
export var inMobile = "style-mod--in-mobile--2Pf18";
export var recommendation = "style-mod--recommendation--1azuO";
export var imageWrapper = "style-mod--image-wrapper--1oBX8";
export var badge = "style-mod--badge--3Zwxp";
export var bodyWrapper = "style-mod--body-wrapper--1nsop";
export var shortDesc = "style-mod--short-desc--1lJSZ";
export var addToCart = "style-mod--add-to-cart--3x9LH";
export var added = "style-mod--added--lGYQl";