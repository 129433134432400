import React from "react";
import {
  useState, useEffect,
  useContext, useRef,
} from "react";
import * as styles from "./style.mod.scss";
import getLoc from "locales";

import {
  addCartItem, removeCartItems,
  updateCartItem, addDiscount,
  removeDiscount,
} from "data/cart";
import GlobalContext from "services/global-context";
import Money from "../money";
import { trackProduct } from "helpers/analytics";
import Loader from "components/loader";

import {
  XLg, PlusLg,
  //Check2
} from "react-bootstrap-icons";
import emptyCartImage from "assets/images/empty-cart-img.png";
import useToggle from "helpers/toggle-state";
import { fetchRecomms } from "data/recommendations";

import { Swiper, SwiperSlide } from "swiper/react";
import SidePanel from "../side-panel";

const Cart = () => {
  const {
    cartOpened, toggleCart,
    checkoutId, cart, locale,
    refreshCart, setLoadingVisible
  } = useContext(GlobalContext);
  let _discountRef = useRef(null);
  const cartItems = cart?.items ? cart.items : [];
  const [loading, setLoading] = useState(true);
  const [cartRefreshing, setCartRefreshing] = useState(true);
  const [showDiscount, toggleDiscount] = useToggle(false);
  const [discountLoading, setDiscountLoading] = useState(false);
  const [discount, setDiscount] = useState(null);
  const [products, setProducts] = useState([]);
  const [discountKeyTimeout, setDiscountKeyTimeout] = useState(null);
  const cartLoc = getLoc()[locale].cart;

  const fillCart = async () => {
    await refreshCart();
    setLoading(false);
  };

  useEffect(async () => {
    setCartRefreshing(true);
    await fillCart();
    setCartRefreshing(false);
  }, [cartOpened]);

  useEffect(async () => {
    if (!cartItems || cartItems === []) return;

    const shopifyIds = cartItems.map((_item) => _item?.productId);
    const recomms = await fetchRecomms(shopifyIds, true);

    setProducts(recomms);
  }, [cart]);

  const addToCart = async (variant) => {
    setLoadingVisible(true);
    await addCartItem(checkoutId, variant.id);
    refreshCart();
    trackProduct({ variant });
  };

  const updateCart = async (variant, qty) => {
    setLoadingVisible(true);
    await updateCartItem(checkoutId, variant.id, qty);
    refreshCart();

    if (qty === 0)
      trackProduct({
        variant, sig: "remove",
        event: "removeFromCart"
      });
  };

  const removeFromCart = async (variant) => {
    setLoadingVisible(true);
    await removeCartItems(checkoutId, [variant.id]);
    refreshCart();
    trackProduct({
      variant, sig: "remove",
      event: "removeFromCart"
    });
  };

  const clearDiscount = async () => {
    setDiscountLoading(true);
    const prevDiscount = discount;
    setDiscount(null);
    await removeDiscount(checkoutId);
    if (prevDiscount !== null) await refreshCart();
    setDiscountLoading(false);
    toggleDiscount();
  };

  const attachDiscount = (withTimeout = true) => {
    const discountCode = _discountRef.value;
    if (discountCode === "") {
      clearDiscount();
      return;
    }

    setDiscount(discountCode);
    clearTimeout(discountKeyTimeout);
    const timeout = setTimeout(async () => {
      setDiscountLoading(true);
      const updatedCart = await addDiscount(checkoutId, discountCode);
      if (updatedCart.discountApplications.length !== 0) {
        await refreshCart();
        toggleDiscount();
      }
      setDiscountLoading(false);
    }, withTimeout ? 750 : 0);
    setDiscountKeyTimeout(timeout);
  };

  const goTo = (url) => window.location.href = url;
  const isEmpty = cartItems.length === 0;
  const concatDetails = (dtls) => dtls.map((dtl) => dtl.value).join(" | ");
  const findOptions = (variant) => variant.selectedOptions.map((option) => (
    {
      name: option.attrs.name.value,
      value: option.attrs.value.value,
    })
  );

  const EmptyCart = () => (
    <SidePanel
      title={ cartLoc.yourEmptyCart }
      open={cartOpened}
      onClose={toggleCart}
      className={`${styles.cart} ${styles.empty}`}
      bodyClass={styles.body}
      closeIconStyle={styles.closeIcon}
    >
      <span className={styles.subtitle}>
        { cartLoc.emptyCartDescl1 }
        { cartLoc.emptyCartDescl2 && (
          <>
            <br/>
            { cartLoc.emptyCartDescl2}
          </>
        )}
      </span>
      <img className={styles.emptyImage} src={emptyCartImage} />
      <button className={styles.checkoutButton} onClick={toggleCart}>{ cartLoc.continueShopping }</button>
    </SidePanel>
  );

  const LoadingCart = () => (
    <SidePanel
      title={ cartLoc.yourEmptyCart }
      open={cartOpened}
      onClose={toggleCart}
      className={`${styles.empty} ${styles.cart}`}
      bodyClass={styles.body}
      closeIconStyle={styles.closeIcon}
    >

      <Loader width="100%" />
    </SidePanel>
  );

  if (loading || (cartRefreshing && isEmpty)) return LoadingCart();
  if (isEmpty) return EmptyCart();

  return (
    <SidePanel
      title={cartLoc.yourCart}
      open={cartOpened}
      onClose={toggleCart}
      className={styles.cart}
      closeIconStyle={styles.closeIcon}
    >
      <div className={styles.body}>
        <div className={styles.cartInfo}>
          <div className={styles.items}>
            {
              cartItems.map((item) => (
                <div key={item.id} className={styles.item}>
                  <a
                    className={styles.removeIcon}
                    onClick={() => removeFromCart(item)}
                  >
                    <XLg />
                  </a>
                  <img className={styles.image} src={item?.image?.src} />
                  <div className={styles.description}>
                    <span className={styles.name}>{ item.title }</span>
                    <span className={styles.details}>{concatDetails(item.variantOptions)}</span>
                    <span className={styles.price}>
                      <span>
                        <Money value={item.qtyPrice} currency={item.priceV2.currencyCode} />
                      </span>
                    </span>
                    <span className={styles.increment}>
                      <span
                        className={styles.dec}
                        onClick={() => updateCart(item, --item.quantity)}
                      >-</span>
                      <span className={styles.val}>{item.quantity}</span>
                      <span
                        className={styles.inc}
                        onClick={() => updateCart(item, ++item.quantity)}
                      >+</span>
                    </span>
                  </div>
                </div>
              ))
            }
          </div>
          <div className={styles.sum}>
            <div className={styles.shipping}>
              <span>{ cartLoc.shipping }</span>
              <span>{ cartLoc.gratis }</span>
            </div>
            {
              showDiscount ?
                <div className={styles.discountWrapper}>
                  <input
                    type="text"
                    className={styles.discountInput}
                    placeholder={cartLoc.discountCode}
                    ref={(_ref) => _discountRef = _ref}
                    onKeyDown={(e) => e.key === "Enter" && attachDiscount(false)}
                  />
                  { discountLoading && <div className={styles.discountLoader} /> }
                  <a className={styles.discountBtn} onClick={() => attachDiscount(false)}>{ cartLoc.remove }</a>
                </div>
                :
                <a className={styles.discount} onClick={toggleDiscount}>
                  {
                    cart.hasDiscounts ?
                      <span className={styles.discountTotal}>
                        <span>{ cart.discount.code }</span>
                        <span>
                          <Money value={-cart.discountedAmount} currency={cart.totalPriceV2.currencyCode} />
                        </span>
                      </span>
                      :
                      "Gutscheincode +"
                  }
                </a>
            }
            <div className={styles.total}>
              <span>{ cartLoc.sum }</span>
              <span>
                <Money value={cart.totalPriceV2.amount} currency={cart.totalPriceV2.currencyCode} />
              </span>
            </div>
            <button className={styles.checkoutButton} onClick={() => goTo(cart.webUrl)}>{ cartLoc.addToCart }</button>
          </div>
        </div>
        <div className={styles.recommendations}>
          <h4 className={styles.title}>{ cartLoc.recommendation }</h4>
          {
            products.map((item) => (
              <div key={item.id} className={styles.recommendation}>
                <div className={styles.imageWrapper}>
                  <span className={styles.badge}>-10%</span>
                  <img className={styles.image} src={ item?.images[0]?.src} />
                  <span className={styles.addToCart} onClick={() => addToCart(item.variants[0])}><PlusLg /></span>
                </div>
                <div className={styles.bodyWrapper}>
                  <span className={styles.name}>{ item.title }</span>
                  <span className={styles.description}>{ item.description.slice(0,90) }...</span>
                  <span className={styles.shortDesc}>{ concatDetails(findOptions(item.variants[0])) }</span>
                  <span className={styles.price}>
                    <Money value={item.variants[0].priceV2.amount} currency={item.variants[0].priceV2.currencyCode} />
                  </span>
                </div>
              </div>
            ))
          }
        </div>
        <div className={`${styles.recommendations} ${styles.inMobile}`}>
          <h4 className={styles.title}>{ cartLoc.recommendation }</h4>
          <Swiper
            slidesPerGroup={1}
            slidesPerView="auto"
            spaceBetween={25}
          >
            {
              products.map((item) => (
                <SwiperSlide key={item.id}>
                  <div key={item.id} className={styles.recommendation}>
                    <div className={styles.imageWrapper}>
                      <span className={styles.badge}>-10%</span>
                      <img className={styles.image} src={ item?.images[0]?.src} />
                      <span className={styles.addToCart} onClick={() => addToCart(item.variants[0])}><PlusLg /></span>
                    </div>
                    <div className={styles.bodyWrapper}>
                      <span className={styles.name}>{ item.title }</span>
                      <span className={styles.description}>{ item.description.slice(0,90) }...</span>
                      <span className={styles.shortDesc}>{ concatDetails(findOptions(item.variants[0])) }</span>
                      <span className={styles.price}>
                        <Money value={item.variants[0].priceV2.amount} currency={item.variants[0].priceV2.currencyCode} />
                      </span>
                    </div>
                  </div>
                </SwiperSlide>
              ))
            }
          </Swiper>
        </div>
      </div>
    </SidePanel>
  );
};

export default Cart;
