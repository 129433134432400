import React, {
  useState, useContext,
  useEffect, useRef,
} from "react";
import { XLg, List } from "react-bootstrap-icons";
import {
  header, top, body,
  head, opened,
  logo, cartIcon, closeIcon,
  contact, login, left, right,
  tabContent, middle,
  active, desktopHeader
}  from "../style.mod.scss";
import DesktopBody from "./body";
import GlobalContext from "services/global-context";
import CartIcon from "assets/svgs/cart-icon.svg";
import Link from "components/link";

const Header = ({ headerData }) => {
  const _headerRef = useRef(null);
  const { toggleCart, setCurrentPanel, currentCustomer } = useContext(GlobalContext);
  const [open, setOpen] = useState(false);
  const [bodyData, setBodyData] = useState(false);
  const [activeTab, setActiveTab] = useState(null);

  const openClass = () => open ? opened : "";
  const activeTabClass = (tab) => activeTab === tab ? active : "";
  const toggleMenu = (isOpen = false) => {
    setOpen(isOpen);
    !isOpen && setActiveTab(null);
  };

  useEffect(() => {
    _headerRef.current.addEventListener("mouseleave", () => toggleMenu(false));
  }, []);

  const changeTab = (newOpen, newData, tab, wTimeout = true) => {
    setTimeout(() => {
      setBodyData(newData);
      const sameTab = (tab === activeTab);

      let openChanged = open !== newOpen;
      let tabState = !sameTab ? tab : activeTab;

      if (openChanged) setOpen(newOpen);
      setActiveTab(tabState);
    }, wTimeout ? 200 : 0);
  };

  const handleLogin = () => {
    setCurrentPanel("login");
  };

  const CloseIcon = (props) => (
    <a
      className={`${closeIcon} ${openClass()}`}
      onClick={props.onClick}>
      { open ? <XLg /> : <List /> }
    </a>
  );

  const isDirect = (lnk) => lnk._type === "directLink";

  const DesktopMenu = (props) => (
    <div className={props.className} onBlur={toggleMenu}>
      {
        headerData?.link?.map((tab) => (
          isDirect(tab) ?
            <Link key={tab._key} to={tab.url} className={`${activeTabClass(tab._key)}`}>{ tab.title }</Link> :
            <Link
              key={tab._key}
              to={tab.url}
              className={`${activeTabClass(tab._key)}`}
              onMouseEnter={() => changeTab(true, tab, tab._key, false)}
            >
              {tab.title}
            </Link>
        ))
      }
    </div>
  );

  return (
    <div className={`${header} ${desktopHeader}`} ref={_headerRef}>
      <div className={head}>
        <div className={top}>
          <div className={left}>
            <CloseIcon onClick={() => toggleMenu(!open)} />
            <Link to="/" ><img src="//cdn.shopify.com/s/files/1/0224/0210/5416/files/logo_Olav_180x.png" className={logo} /></Link>
          </div>
          <DesktopMenu className={middle} />
          <div className={right}>
            <Link to="/kontakt" className={contact}>Kontakt</Link>
            {currentCustomer === null && <button className={login} onClick={handleLogin}>Login</button>}
            {currentCustomer !== null && <Link className={login} to="/account">Account</Link>}

            <a onClick={toggleCart} className={cartIcon}><CartIcon /></a>
          </div>
        </div>
      </div>
      <div className={`${body} ${openClass()}`}>
        <div className={tabContent}>
          <DesktopBody data={bodyData} />
          <CloseIcon onClick={() => toggleMenu(false)}/>
        </div>
      </div>
    </div>
  );
};

export default Header;
