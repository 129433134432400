import React, { useState, useEffect } from "react";
import { getGatsbyImageData } from "gatsby-source-sanity";
import { GatsbyImage } from "gatsby-plugin-image";
import sanityConfig from "services/sanity-config";

const getGatsbyImage = (img, width) => {
  return getGatsbyImageData(
    img,
    { maxWidth: width || 2400 },
    sanityConfig()
  );
};

const Image = ({ image, hoverImage, width, className, alt }) => {
  const [thisImage, setThisImage] = useState(getGatsbyImage(image, width));
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    let hImgData = null;
    const imgData = getGatsbyImage(image, width);
    if (hoverImage) hImgData = getGatsbyImage(hoverImage, width);

    setThisImage(hovered ? hImgData || imgData : imgData);
  }, [hovered]);

  return <GatsbyImage
    image={thisImage}
    alt={alt || ""}
    className={className}
    onMouseOver={() => hoverImage && setHovered(true)}
    onMouseOut={() => hoverImage && setHovered(false)}
  />;
};

export default Image;
