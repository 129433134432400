import React, { useContext, useState, useEffect } from "react";
import SidePanel from "../side-panel";
import * as styles from "./styles.mod.scss";
import getLoc from "locales";
import GlobalContext from "services/global-context";
import { Formik } from "formik";
import TextInput from "../text-input";
import * as Yup from "yup";
import { associateCheckout, createCustomerAccessToken, setCustomerAccessToken } from "services/shopify-account";
import { getExistingCheckoutId } from "services/user-checkout-id";
import { navigate } from "@reach/router";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required(),
  password: Yup.string()
    .required()
});

const Login = ({ wasOpened }) => {
  const [opened, setOpened] = useState(false);
  const [closing, setClosing] = useState(false);
  const { locale, setCurrentPanel, getCustomerProfile } = useContext(GlobalContext);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const loc = getLoc()[locale].login;

  const handleSubmit = ({ email, password }, { setFieldError }) => {
    setSubmitDisabled(true);


    createCustomerAccessToken({
      email, password
    }).then((response) => {
      setSubmitDisabled(false);
      if (response.data.customerAccessTokenCreate?.customerUserErrors?.length > 0) {
        const unknownCustomer = response.data.customerAccessTokenCreate.customerUserErrors.find(k => k.code === "UNIDENTIFIED_CUSTOMER");

        if (unknownCustomer) {
          setFieldError("email", loc.unknownCustomer);
        }
      } else if (response.data.customerAccessTokenCreate?.customerAccessToken) {
        setCustomerAccessToken(response.data.customerAccessTokenCreate.customerAccessToken);
        getCustomerProfile(() => {
          navigate("/account");
        });
        setCurrentPanel(null);

        if (getExistingCheckoutId()) {
          associateCheckout(getExistingCheckoutId());
        }
      }
    });
  };

  const handleRegistration = () => {
    setCurrentPanel("registration"); return false;
  };

  useEffect(() => {
    setTimeout(() => !closing && setOpened(true), 0);
  });

  const handleClose = () => {
    setClosing(true);
    setOpened(false);
    setTimeout(() => setCurrentPanel(null), 250);
  };

  const isOpen = () => opened || (!closing && wasOpened);

  return <SidePanel
    open={true}
    className={`${styles.sidePanel} ${isOpen() ? styles.opened : ""}`}
    bodyClass={styles.body}
    onClose={handleClose}
  >
    <h3>{loc.title}</h3>
    <p>{loc.subtitle}</p>
    <Formik
      initialValues={{
        email: "", password: ""
      }}
      onSubmit={handleSubmit}
      validationSchema={LoginSchema}
    >
      {
        ({ errors, touched, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className={styles.formInner}>
              <div className="row">
                <div className="col-12">
                  <TextInput type="text" name="email" onChange={handleChange} placeholder={loc.email}  errors={errors.email} touched={touched.email} />
                </div>
                <div className="col-12">
                  <TextInput type="password" name="password" onChange={handleChange} placeholder={loc.password} errors={errors.password} touched={touched.password} />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p className={styles.links}>
                    {loc.noAccount} <a href="#" onClick={handleRegistration}>{loc.register}</a>
                  </p>
                </div>
              </div>
            </div>
            <button type="submit" disabled={submitDisabled} className={styles.button}>{loc.login}</button>
          </form>
        )
      }

    </Formik>


  </SidePanel>;
};

export default Login;
