import React, { useState } from "react";
import * as styles from "./styles.mod.scss";

const TextInput = (props) => { 
  const { touched, errors, onChange } = props;
  const [hasValue, setHasValue] = useState(false);

  const hasErrors = touched && errors;

  return <div className={`${styles.input} ${hasValue ? styles.hasValue : ""} ${hasErrors ? styles.hasErrors : ""}`}>
    <input {...props} onChange={(e) => { 
      setHasValue(e.target.value !== "");
      onChange(e);
    }} touched={undefined}/>
    <span>{props.placeholder}</span>

    {hasErrors ? <div className={styles.errors}>{errors}</div> : null}
  </div>;
};

export default TextInput;