import React, { useState, useEffect } from "react";
import "assets/scss/layouts/base-layout.scss";
import UserCheckoutId from "services/user-checkout-id";
import GlobalContext from "services/global-context";

import useToggle from "helpers/toggle-state";
import LoadingBar from "components/loading-bar";
import AnnouncementBar from "components/announcement-bar";
import Header from "components/header";

import Footer from "components/footer";
import Cart from "components/cart";
import MetaTags from "components/seo";
import { trackProduct } from "helpers/analytics";
import { fetchCart, addCartItem } from "data/cart";


import AccountPanel from "components/account-panel";
import { associateCheckout, getCustomer, logout } from "services/shopify-account";

export default function BaseLayout({ data, meta, children }) {
  const [loadingVisible, setLoadingVisible] = useState(false);
  const [cart, setCart] = useState({});
  const [cartOpened, toggleCartOpened] = useToggle();
  const [checkoutId, setCheckoutId] = useState(null);
  const [currentPanel, setCurrentPanel] = useState({
    prev: null,
    now: null,
  });
  const [loadingCustomer, setLoadingCustomer] = useState(true);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [loyaltyLion, setLoyaltyLion] = useState(null);


  useEffect(async () => {
    const checkoutId = await UserCheckoutId();
    setCheckoutId(checkoutId);

    associateCheckout(checkoutId);
  }, [cartOpened]);

  useEffect(() => {
    getCustomerProfile();
    syncBodyOverlay();
  }, []);

  const refreshCart = async () => {
    setLoadingVisible(true);
    const cart = await fetchCart(checkoutId);
    if (cart) setCart(cart);
    setLoadingVisible(false);
  };

  const addToCart = async (variant) => {
    await addCartItem(checkoutId, variant.id);
    refreshCart();
    if (!cartOpened) toggleCartEvent();
    trackProduct({ variant });
  };

  const getCustomerProfile = (callback) => {
    getCustomer().then((response) => {
      if (response?.data?.customer) {
        setCurrentCustomer(response.data.customer);
        callback && callback();
      }
      setLoadingCustomer(false);
    });
  };

  const logOut = () => {
    logout();
    setCurrentCustomer(null);
    setLoadingCustomer(true);
    setTimeout(() => setLoadingCustomer(false), 100);
  };

  const toggleBodyOverlay = (opened) => {
    const bodyClassList = document.body.classList;

    if (opened) {
      bodyClassList.add("rm-overlay");
      setTimeout(() => {
        bodyClassList.toggle("with-overlay");
        bodyClassList.remove("rm-overlay");
      }, 500);
    } else {
      bodyClassList.toggle("with-overlay");
    }
  };

  const syncBodyOverlay = () => {
    const bodyClassList = document.body.classList;
    if (!cartOpened && [...bodyClassList]?.includes("with-overlay")) {
      bodyClassList.remove("with-overlay");
      bodyClassList.remove("rm-overlay");
    }
  };

  const toggleCartEvent = () => {
    toggleBodyOverlay(cartOpened);
    toggleCartOpened();
  };

  const setHistoricPanel = (_panel) => {
    setCurrentPanel((_prev) => ({
      prev: _panel ? _prev.now : null,
      now: _panel ? _panel : null,
    }));
  };

  const globalState = {
    cartOpened: cartOpened,
    toggleCart: toggleCartEvent,
    locale: "de",
    checkoutId: checkoutId,
    setCheckoutId: setCheckoutId,
    cart: cart,
    setCart: setCart,
    refreshCart: refreshCart,
    loadingVisible: loadingVisible,
    setLoadingVisible: setLoadingVisible,
    addToCart: addToCart,
    toggleBodyOverlay: toggleBodyOverlay,
    currentPanel,
    setCurrentPanel: setHistoricPanel,
    currentCustomer,
    loadingCustomer,
    getCustomerProfile,
    logOut,
    loyaltyLion,
    setLoyaltyLion,
    specialOffers: data?.specialOffers
  };

  const announcementBar = data?.AnnouncementBar;
  const headerSettings = data?.Header;
  const footerSettings = data?.Footer;
  const socialSettings = data?.Social;
  const headerInfo = {
    headerSettings, socialSettings
  };
  const footerInfo = {
    footerSettings, socialSettings
  };

  return <GlobalContext.Provider value={globalState}>
    <MetaTags meta={meta} />
    <LoadingBar />
    { announcementBar && <AnnouncementBar data={announcementBar} /> }
    { headerSettings && <Header data={headerInfo} /> }
    <Cart />
    <AccountPanel currentPanel={globalState.currentPanel} />
    { children }
    { footerSettings && <Footer data={footerInfo} /> }
  </GlobalContext.Provider>;
}
