import ShopifyClient from "services/shopify-client";

export const getExistingCheckoutId = () => localStorage.getItem("checkoutId");

const UserCheckoutId = async () => {
  const checkoutId = getExistingCheckoutId();
  if (checkoutId && await ShopifyClient.checkout.fetch(checkoutId)) { 
    return checkoutId;
  }

  const newCheckout = await ShopifyClient.checkout.create();
  localStorage.setItem("checkoutId", newCheckout.id);
};

export default UserCheckoutId;
