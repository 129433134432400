import React, { useContext, useState } from "react";
import GlobalContext from "services/global-context";
import { 
  field, footerMail,
  emailInput, consentContainer,  
  consentCheckbox, successMsg
} from "./style.mod.scss";
import getLoc from "locales";
import ArrowRight from "assets/svgs/arrow-right.svg";
import { Formik, Field, Form } from "formik";

const ActiveCampaignForm = () => {
  const { locale } = useContext(GlobalContext);
  const formLoc = getLoc()[locale].footer;
  const [sucessMsg, setSuccessMsg] = useState("");

  return (
    <div className={field} id="activeCampaignForm">
      <Formik
        initialValues= {{ email: "" }}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true);
          fetch(`https://myolave.activehosted.com/proc.php?u=13&f=13&s=null&c=0&act=sub&v=2&jsonp=true&email=${values.email}`, {
            method: "GET", 
            mode: "no-cors",
            headers: { "Content-Type": "application/json", },
          })
            .then(() => {
              actions.setSubmitting(true);
              setSuccessMsg(formLoc.thankYouMsg);
              actions.setSubmitting(false);
              actions.resetForm();
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className={footerMail}>
              <Field className={emailInput} name="email" placeholder={formLoc.yourEmail} />
              <button type="submit" disabled={isSubmitting} id="submitForm"> <ArrowRight/> </button>
            </div>
            <div className={consentContainer}>
              <input className={consentCheckbox} id="checkbox" type="checkbox"></input>
              <label htmlFor="checkbox"> {formLoc.agreeCheckbox} </label>
            </div>
            <span className={successMsg}>
              {sucessMsg}
            </span>
          </Form>
        )}
      </Formik>
    </div>
  );
};


export default ActiveCampaignForm;
