import React from "react";

const Money = ({ value, currency }) => {
  const isFraction = value % 1 !== 0;

  if ((typeof window !== "undefined")) {
    return <>{ new Intl.NumberFormat("de-DE", {
      style: "currency", currency,
      maximumFractionDigits: isFraction ? 2 : 0,
      minimumFractionDigits: isFraction ? 2 : 0
    }).format(value).replace(/\s/g, "")}</>;
  }

  return <>{parseInt(value)}</>;
};
export default Money;
