import ShopifyClient from "services/shopify-client";

const fetchProducts = async (pageSize) => {
  const products = await ShopifyClient.product.fetchAll(pageSize);
  return products;
};

const fetchByHandle = async (handle) => {
  const product = await ShopifyClient.product.fetchByHandle(handle);
  return product;
};

export {
  fetchProducts,
  fetchByHandle,
};
