import React, { useState, useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  footer, footerTitle, footerContent, footerList,
  listTitle, listUl, footerNewsletter, footerPhone,
  footerEmail, footerForm, socialIcons, language,
  divider, bottomFooter, leftSide, rightSide,
  footerFlag
}  from "./style.mod.scss";
import ReactFlagsSelect from "react-flags-select";
import GlobalContext from "services/global-context";

import facebookIcon from "assets/images/social/fb.svg";
import instagramIcon from "assets/images/social/insta.svg";
import pinterestIcon from "assets/images/social/pint.svg";
import Link from "components/link";

import getLoc from "locales";
import ActiveCampaignForm from "components/active-campaign-form";

const TextLinks = ({ links }) => {
  const Links = links.map((lnkGroup) => {
    const groupLinks = lnkGroup.links.map((lnk) => (
      <li key={lnk._key} className="">
        <Link to={lnk.url}>{lnk.title}</Link>
      </li>
    ));

    return (
      <div key={lnkGroup._key} className={footerList}>
        <p className={listTitle}>{ lnkGroup.title }</p>
        <ul className={listUl}>
          { groupLinks }
        </ul>
      </div>
    );
  });

  return Links;
};

const Footer = ({ data }) => {
  const { locale } = useContext(GlobalContext);
  const [selected, setSelected] = useState(locale?.toUpperCase());
  const { footerSettings, socialSettings } = data;
  const footerLoc = getLoc()[locale].footer;

  const languages = ["DE"];
  const langLabels = ("Custom Labels", { "DE": "Deutschland" });

  return (
    <div className={footer}>
      <Container>
        <Row>
          <Col lg="4">
            <h1 className={footerTitle}>{ footerSettings.title }</h1>
            <div className={footerContent}>
              <TextLinks links={footerSettings.links} />
            </div>
          </Col>
          <Col lg="4"></Col>
          <Col lg="4">
            <h4 className={footerNewsletter}>{footerLoc.formTitle}</h4>
            <div className={footerForm}>
              <ActiveCampaignForm/>
            </div>
            <p className={footerPhone}>{ footerLoc.phone } <Link className={footerPhone} to={`tel:${socialSettings.phone}`}>{ socialSettings.phone }</Link></p>
            <p className={footerEmail}>{ footerLoc.email } <Link to={`mailto:${ socialSettings.email }`}>{ socialSettings.email }</Link></p>
            <div className={socialIcons}>
              <Link to={ socialSettings.facebook } target="_blank" rel="noreferrer">
                <img src={facebookIcon}  alt="Facebook icon" />
              </Link>
              <Link to={ socialSettings.instagram } target="_blank" rel="noreferrer">
                <img src={instagramIcon}  alt="Instagram icon" />
              </Link>
              <Link to={ socialSettings.pinterest } target="_blank" rel="noreferrer">
                <img src={pinterestIcon} alt="Pinterest icon" />
              </Link>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <div className={language}>
              <ReactFlagsSelect
                optionsSize={904}
                selectButtonClassName={footerFlag}
                countries={languages}
                customLabels={langLabels}
                placeholder={footerLoc.language}
                selected={selected}
                onSelect={code => setSelected(code) }
              />
            </div>
            <div className={divider}></div>
          </Col>
          <Col lg="12">
            <div className={bottomFooter}>
              <div className={leftSide}>
                <p>&copy; <Link to="/">Olav</Link> {(new Date().getFullYear())} </p>
              </div>
              <div className={rightSide}>
                {
                  footerSettings?.impressumLink?.map((lnk) => (
                    <p key={lnk._key}>
                      <Link to={lnk.url}>{lnk.title}</Link>
                    </p>
                  ))
                }
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
