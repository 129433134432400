import getMetaId from "./get-meta-id";

const TRACK_EVENT = "data-track-event";
const TRACK_TYPE = "data-track-type";
const TRACK_PROPS = "data-track-props";
const TRACK_IMPRESSION = "data-track-impression";
const TRACKED = "data-tracked";

const ser = (payload) => JSON.stringify(payload);
const deser = (payload) => JSON.parse(payload);

const track = (props) => {
  const isBrowser = typeof window !== "undefined";
  const gtmInitialized = typeof dataLayer !== "undefined";
  if (!isBrowser || !gtmInitialized) return setTimeout(() => track(props), 500);

  // eslint-disable-next-line no-undef
  dataLayer.push(props);
};

const initAnalytics = ({ select = null, node = null }) => {
  const isBrowser = typeof window !== "undefined";
  const gtmInitialized = typeof dataLayer !== "undefined";
  if (!isBrowser || !gtmInitialized) return setTimeout(() => initAnalytics(select), 500);

  let root = select ? document.querySelector(select) : null;
  if (!root) root = node ? node : document;
  const events = root.querySelectorAll(`[${TRACK_EVENT}], [${TRACK_PROPS}]`);

  events.forEach((evEl) => {
    if (evEl?.dataset?.tracked === "true") return;

    let { trackEvent, trackProps, trackType } = evEl.dataset;
    trackProps = deser(trackProps);
    let type = trackType || "click";

    evEl.addEventListener(type, () => {
      track({
        event: trackEvent,
        ...trackProps
      });
    });

    evEl.setAttribute(TRACKED, "true");
    evEl.removeAttribute(TRACK_EVENT);
    evEl.removeAttribute(TRACK_PROPS);
    evEl.removeAttribute(TRACK_TYPE);
  });
};

const mapEcommerce = (variant, qty, sig) => {
  let type = variant.sourceData ? "sanity" : null;
  if (!type) type = variant.refetchQuery ? "graphql" : "model";

  if (type === "sanity") return {
    currencyCode: variant.sourceData.priceV2.currencyCode,
    [sig]: {
      products: [{
        name: variant.sourceData.title,
        id: getMetaId(variant.sourceData.id),
        price: variant.sourceData.priceV2.amount,
        brand: "MyOlav",
        category: null, // N/A
        variant: null, // N/A
        quantity: qty
      }]
    }
  };

  return {
    currencyCode: variant.priceV2.currencyCode,
    [sig]: {
      products: [{
        name: variant.title,
        id: getMetaId(variant.id),
        price: variant.priceV2.amount,
        brand: "MyOlav",
        category: variant?.variantCategory?._ref,
        variant: null, // N/A
        quantity: qty
      }]
    }
  };
};

const trackProduct = ({
  variant, sig = "add",
  event = "addToCart",
  qty = 1
}) => {
  track({
    event: event,
    ecommerce: mapEcommerce(variant, qty, sig)
  });
};

const trackPDPView = ({ variant , product }) => {
  track({
    event: "productDetailView",
    ecommerce: {
      detail: {
        actionField: {},    // "detail" actions have an optional list property.
        products: [{
          name: product.title,
          id: atob(variant.sourceData.id).replace("gid://shopify/ProductVariant/", ""),
          price: parseFloat(variant.sourceData.priceV2.amount),
          brand: "MyOlav",
          category: null, // N/A
          variant: variant.sourceData.title, // N/A
        }]
      }
    }
  });
};

const trackProductClick = ({ product, variant, key }) => {
  track({
    event: "productClick",
    ecommerce: {
      click: {
        actionField: { list: "Collection Pages" },
        products: [{
          name: variant.data.title,
          id: variant.data.sku,
          price: variant.data.price,
          brand: "MyOlav",
          category: product.collectionHandle,
          variant: variant?.handle?.current,
          position: key,
        }]
      }
    },
  });
};

const trackImpressions = ({ select = null, node = null }) => {
  const isBrowser = typeof window !== "undefined";
  const gtmInitialized = typeof dataLayer !== "undefined";
  if (!isBrowser || !gtmInitialized) return setTimeout(() => initAnalytics(select), 500);
  if (window.impressionsTracked) return;

  const isInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  let root = select ? document.querySelector(select) : null;
  if (!root) root = node ? node : document;
  let monitoring = false;

  document.addEventListener("scroll", () => {
    if (monitoring) return;
    const nodes = root.querySelectorAll(`[${TRACK_IMPRESSION}]`);
    nodes.forEach((_node) => {
      if (!isInViewport(_node)) return;
      if (_node?.dataset?.tracked === "true") return;

      let { trackEvent, trackProps } = _node.dataset;
      trackProps = deser(trackProps);
      track({
        event: trackEvent,
        ...trackProps
      });

      _node.setAttribute(TRACKED, "true");
      _node.removeAttribute(TRACK_IMPRESSION);
      _node.removeAttribute(TRACK_PROPS);
    });
    monitoring = false;
  });

  window.impressionsTracked = true;
};

export {
  initAnalytics,
  track,
  trackProduct,
  trackPDPView,
  trackProductClick,
  trackImpressions,
  ser,
  deser
};
