import { getGatsbyImageData } from "gatsby-source-sanity";

const sanityConfig = {
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET
};

const getImagePath = (node, srcSet = false, width = 2400) => {
  if (node === undefined) return;

  const gImgData = getGatsbyImageData(
    node,
    { maxWidth: width },
    sanityConfig
  );

  const processSrc = (image) => srcSet ? image.replace(/(\r\n|\n|\r)/gm, "") : image.split(" ")[0];

  if (gImgData.images.sources.length > 0){
    const imageSrc = processSrc(gImgData.images.sources[0].srcSet);
    return imageSrc;
  }

  if (gImgData.images.fallback){
    const imageSrc = processSrc(gImgData.images.fallback.src);
    return imageSrc;
  }
};

export default getImagePath;
