// extracted by mini-css-extract-plugin
export var headerBodyWrapper = "body-mod--header-body-wrapper--2WBCd";
export var headerSlider = "body-mod--header-slider--27MxS";
export var sliderTitle = "body-mod--slider-title--1loOA";
export var headerLink = "body-mod--header-link--2SOAJ";
export var headerImage = "body-mod--header-image--3NkyS";
export var headerDescription = "body-mod--header-description--hyeRk";
export var headerText = "body-mod--header-text--fs_S3";
export var headerTextIcon = "body-mod--header-text-icon--3jpoR";
export var combinedLinks = "body-mod--combined-links--3X8qQ";
export var textLinks = "body-mod--text-links--2NTQA";
export var linkGroupWrapper = "body-mod--link-group-wrapper--3dlfj";
export var linkGroup = "body-mod--link-group--3SguA";
export var link = "body-mod--link--3Ri6e";
export var optImageWrapper = "body-mod--opt-image-wrapper--3Z83I";
export var optImage = "body-mod--opt-image--3ImQo";
export var optDesc = "body-mod--opt-desc--UFba8";
export var optText = "body-mod--opt-text--3HyJi";
export var optIcon = "body-mod--opt-icon--292l4";
export var linksWrapper = "body-mod--links-wrapper--2Ca8R";
export var contactLinks = "body-mod--contact-links--2fFlY";
export var language = "body-mod--language--2EpzR";
export var socialIcons = "body-mod--social-icons--Qu0F5";