import React from "react";
import * as styles from "./style.mod.scss";
import {
  XLg,
  //Check2
} from "react-bootstrap-icons";


const SidePanel = (props) => {
  const {
    title, children, open,
    className, bodyClass, onClose,
    closeIconStyle,
  } = props;

  return (
    <div className={`${styles.sidePanel} ${open ? styles.opened : ""} ${className}`}>
      <a
        className={closeIconStyle ? closeIconStyle : styles.closeIcon}
        onClick={onClose}>
        <XLg />
      </a>

      <div className={`${styles.header} ${title === undefined ? styles.noShadow : ""}`}>
        {title && <h4 className={styles.title}>{ title }</h4>}
      </div>
      <div className={bodyClass}>
        {children}
      </div>
    </div>
  );
};

export default SidePanel;
