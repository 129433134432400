import { fetchByHandle } from "./products";

const API_URL = (productId) => `https://myolav.com/recommendations/products.json?product_id=${productId}&limit=4`;
const PROXY_URL = (url) => `https://api.allorigins.win/get?url=${encodeURIComponent(url)}`;

const getProductId = (b64ProductId) => {
  const gId = atob(b64ProductId);
  const match = gId.match("([^/]*)$");
  const productId = match ? match[0] : null;

  return productId;
};

const fetchRecomms = async (shopifyIds, proxy = false) => {
  if (!shopifyIds || shopifyIds.length === 0) return [];

  const randShopifyId = shopifyIds[Math.floor(Math.random() * shopifyIds.length)];
  const productId = getProductId(randShopifyId);

  const reqUrl = proxy ? PROXY_URL(API_URL(productId)) : API_URL(productId);
  const response = await fetch(reqUrl).then((res) => res.json());
  console.log(response);

  if (proxy) {
    if (!response.status || response.status.http_code !== 200) return [];
    const contents = JSON.parse(response.contents);
    const handles = contents.products.map((_prod) => _prod.handle);
    const recommendations = [];

    for (const handle of handles) {
      const product = await fetchByHandle(handle);
      recommendations.push(product);
    }

    return recommendations;
  }

  if (!response.status || response.status.code !== 200) return [];
  const recommendations = response.products;
  return recommendations;
};

const getVariantId = (variantId) => {
  if (!variantId) return "";

  const gId = `gid://shopify/ProductVariant/${variantId}`;
  const enVariantId = btoa(gId);
  return enVariantId;
};

export {
  fetchRecomms,
  getVariantId,
};
