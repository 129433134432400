import React from "react";
import DesktopHeader from "./desktop";
import MobileHeader from "./mobile";

const Header = ({ data }) => {
  const { headerSettings, socialSettings } = data;

  return (
    <>
      <DesktopHeader
        headerData={headerSettings.desktopHeader}
        socialData={socialSettings}
      />
      <MobileHeader
        headerData={headerSettings.mobileHeader}
        socialData={socialSettings}
      />
    </>
  );
};

export default Header;
