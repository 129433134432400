import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import { headerLinkWrapper, richtextBtn } from "./style.mod.scss";
import Link from "components/link";
import getImagePath from "helpers/get-image-path";

const LinkHeader = ({ mark, children }) => (
  <span id={mark?.href} className={headerLinkWrapper}>
    { children }
  </span>
);

const ImageRenderer = ({ node }) => {
  console.log(node.alt);
  return (
    <img srcSet={getImagePath(node, true)} alt={node.alt} />
  );
};

const ButtonRenderer = ({ node: { btnLink, btnTitle } }) => (
  <Link to={btnLink} className={richtextBtn}>
    { btnTitle }
  </Link>
);

const highlight = (props) => <span style={{ backgroundColor: props.mark.color }}>{props.children}</span>;

const ColorRender = (node) => (
  <span style={{ color: node.mark.hex }}>{node.children}</span>
);

const serializers = {
  types: {
    image: ImageRenderer,
    limitedImage: ImageRenderer,
    button: ButtonRenderer,
  },
  marks: {
    sectionHeader: LinkHeader,
    color: ColorRender,
    highlight: highlight
  },
};

const RichText = ({ className, blocks, _serializers }) => {
  return (
    <BlockContent
      className={className}
      blocks={blocks}
      serializers={_serializers || serializers}
    />
  );
};

export default RichText;
