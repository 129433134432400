// extracted by mini-css-extract-plugin
export var footer = "style-mod--footer--3mb7D";
export var footerTitle = "style-mod--footer-title--3_dlA";
export var footerContent = "style-mod--footer-content--1W6-V";
export var footerList = "style-mod--footer-list--cZUGJ";
export var listTitle = "style-mod--list-title--3YLVM";
export var listUl = "style-mod--list-ul--9c9KV";
export var footerPhone = "style-mod--footer-phone--zJqm-";
export var footerEmail = "style-mod--footer-email--22Tty";
export var footerForm = "style-mod--footer-form--2u97l";
export var socialIcons = "style-mod--social-icons--2kWHe";
export var footerNewsletter = "style-mod--footer-newsletter--_tvAm";
export var language = "style-mod--language--2IIax";
export var divider = "style-mod--divider--1lpqE";
export var bottomFooter = "style-mod--bottom-footer--1DmPJ";
export var leftSide = "style-mod--left-side--Tsbap";
export var rightSide = "style-mod--right-side--THyme";
export var footerFlag = "style-mod--footer-flag--33f7y";