import { ObjectModel, ArrayModel } from "objectmodel";

const CartItemImage = new ObjectModel({
  id: String,
  src: String,
  altText: [String],
});

const CartItemModel = new ObjectModel({
  id: String,
  productId: String,
  productHandle: String,
  variantId: String,
  quantity: Number,
  title: String,
  price: String,
  image: [CartItemImage],
  images: [ArrayModel(CartItemImage)],
  priceV2: new ObjectModel({
    amount: String,
    currencyCode: String,
  }),
  sku: String,
  available: Boolean,
  discount: [new ObjectModel({
    amount: [String],
    code: [String],
    perc: [Number],
  })],
  variantOptions: [ArrayModel(new ObjectModel({
    name: String,
    value: String,
  }))],
  qtyPrice: Number,
  discountedPrice: [Number],
});

export default CartItemModel;
