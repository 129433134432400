// extracted by mini-css-extract-plugin
export var header = "style-mod--header--10aWt";
export var mobileHeader = "style-mod--mobile-header--2yfb2";
export var desktopHeader = "style-mod--desktop-header--20Qlg";
export var head = "style-mod--head--1DM15";
export var top = "style-mod--top--3451E";
export var left = "style-mod--left--3I7rp";
export var closeIcon = "style-mod--close-icon--3OXgM";
export var opened = "style-mod--opened--QsHA9";
export var logo = "style-mod--logo--C2XOE";
export var middle = "style-mod--middle--1DVzv";
export var active = "style-mod--active--u3-qw";
export var right = "style-mod--right--1qDzI";
export var cartIcon = "style-mod--cart-icon--2Vj2Q";
export var login = "style-mod--login--3KeRW";
export var contact = "style-mod--contact--vjTDj";
export var menu = "style-mod--menu--1pAPG";
export var body = "style-mod--body--1UDmX";
export var tabContent = "style-mod--tab-content--3EotX";