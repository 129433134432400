import React from "react";
import { announcementBar, headerText } from "./style.mod.scss";
import Link from "components/link";

const AnnouncementBar = ({ data }) => {
  if (!data.show) return <></>;

  const wrapperStyle = {};
  if (data.txtColor && data.txtColor.hex) wrapperStyle.color = data.txtColor.hex;
  if (data.bgColor && data.bgColor.hex) wrapperStyle.backgroundColor = data.bgColor.hex;

  return (
    <Link className={announcementBar} style={wrapperStyle} to={data.url}>
      <span className={headerText}>
        { data.text }
      </span>
    </Link>
  );
};

export default AnnouncementBar;
