import Client from "services/shopify-client";
import Cart from "models/cart-model";
import CartItem from "models/cart-item-model";

// Fill and fetch the Cart Object
const fetchCart = async (checkoutId) => {
  if (!checkoutId) return null;

  const checkout = await Client.checkout.fetch(checkoutId);
  const lineItems = checkout.lineItems;
  const createdAt = new Date(checkout.createdAt);
  const totalPriceV2 = checkout.totalPriceV2;
  const webUrl = checkout.webUrl;
  const hasDiscounts = checkout.discountApplications.length !== 0;
  const discount = hasDiscounts ? checkout.discountApplications[0] : null;
  let discountedAmount = 0;

  const items = lineItems.map((item) => {
    const itemHasDiscounts = item.discountAllocations.length !== 0;
    let itemDiscount = {};
    let firstDiscount = null;
    let variantOptions = null;
    let discountedPrice = parseFloat(item.variant.priceV2.amount);
    const qtyPrice = parseFloat(item.variant.priceV2.amount) * item.quantity;

    if (hasDiscounts && itemHasDiscounts) {
      firstDiscount = item.discountAllocations[0];
      const itemDiscountAmount = firstDiscount?.allocatedAmount?.amount;
      if (itemDiscountAmount) discountedAmount += parseFloat(itemDiscountAmount);
      itemDiscount = {
        amount: firstDiscount?.allocatedAmount?.amount,
        code: firstDiscount?.discountApplication?.code,
        perc: firstDiscount?.discountApplication?.value?.percentage,
      };

      discountedPrice = discountedPrice - parseFloat(itemDiscountAmount);
    }

    if (item.variant) {
      variantOptions = item.variant.selectedOptions.map((option) => (
        {
          name: option.attrs.name.value,
          value: option.attrs.value.value,
        })
      );

    }

    const cartItem = new CartItem({
      id: item.id,
      title: item.title,
      quantity: item.quantity,
      sku: item.variant.sku,
      image: item.variant.image,
      available: item.variant.available,
      variantId: item.variant.id,
      productId: item.variant.product.id,
      productHandle: item.variant.product.handle,
      price: item.variant.price,
      priceV2: item.variant.priceV2,
      qtyPrice: qtyPrice,
      discountedPrice: discountedPrice,
      discount: itemDiscount,
      variantOptions: variantOptions,
    });

    return cartItem;
  });

  const cart = new Cart({
    items: items,
    createdAt: createdAt,
    totalPriceV2: totalPriceV2,
    webUrl: webUrl,
    discount: {
      code: discount?.code,
      perc: discount?.value?.percentage,
      applicable: discount?.applicable,
    },
    discountedAmount: discountedAmount,
    hasDiscounts: hasDiscounts,
  });

  return cart;
};

// Add to Cart
const addCartItem = async (checkoutId, variantId) => {
  const lineItem = [
    {
      variantId: variantId,
      quantity: 1,
    }
  ];

  const updatedCheckout = await Client.checkout.addLineItems(checkoutId, lineItem);
  return updatedCheckout;
};

// Update Cart Item
const updateCartItem = async (checkoutId, itemId, qty) => {
  const lineItem = [{
    id: itemId,
    quantity: qty
  }];

  const updatedCheckout = await Client.checkout.updateLineItems(checkoutId, lineItem);
  return updatedCheckout;
};

// Remove Cart Item
const removeCartItems = async (checkoutId, lineItemIds) => {
  const updatedCheckout = await Client.checkout.removeLineItems(checkoutId, lineItemIds);
  return updatedCheckout;
};

// Add discount
const addDiscount = async (checkoutId, discountCode) => {
  const updatedDiscount = await Client.checkout.addDiscount(checkoutId, discountCode);
  return updatedDiscount;
};

// Remove discount
const removeDiscount = async (checkoutId) => {
  const updatedDiscount = await Client.checkout.removeDiscount(checkoutId);
  return updatedDiscount;
};

export {
  fetchCart,
  addCartItem,
  updateCartItem,
  removeCartItems,
  addDiscount,
  removeDiscount,
};
