import React from "react";
import Login from "../login";
import Register from "../register";

const AccountPanel = (props) => {
  const { currentPanel } = props;

  if (currentPanel?.now === "registration") {
    return <Register wasOpened={currentPanel?.prev !== null} />;
  } else if (currentPanel?.now === "login") {
    return <Login wasOpened={currentPanel?.prev !== null} />;
  }

  return null;
};

export default AccountPanel;
