import React from "react";
import { loader, ldsEllipsis, fullscreen } from "./style.mod.scss";

const LoaderDot = ({ color }) => (
  <div style={{ backgroundColor: color ? color : "#18404B" }} />
);

const Loader = ({ color, full, width }) => (
  <div
    className={`${loader} ${full ? fullscreen : ""}`}
    style={{ width: width ? width : "" }}
  >
    <div className={ldsEllipsis}>
      <LoaderDot color={color} />
      <LoaderDot color={color} />
      <LoaderDot color={color} />
      <LoaderDot color={color} />
    </div>
  </div>
);

export default Loader;
