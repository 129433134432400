import React, { useContext, useState } from "react";
import {
  headerBodyWrapper, headerLink, headerImage,
  headerDescription, headerTextIcon, headerText,
  link, textLinks, linkGroupWrapper, linkGroup,
  headerSlider, sliderTitle, socialIcons,
  linksWrapper, contactLinks, language
}  from "./body.mod.scss";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Image from "components/image";
import GlobalContext from "services/global-context";


import { Telephone, Envelope } from "react-bootstrap-icons";
import facebookIcon from "assets/images/social/fb.svg";
import instagramIcon from "assets/images/social/insta.svg";
import pinterestIcon from "assets/images/social/pint.svg";
import Link from "components/link";
import getLoc from "locales";
import ReactFlagsSelect from "react-flags-select";

// install Swiper modules
SwiperCore.use([Pagination]);

// Image Links Component
const ImageLinks = ({ data }) => {
  const imageData = data.link.find((dt) => dt._type === "imageLink");

  return (
    <div className={headerSlider}>
      <span className={sliderTitle}>{imageData.title}</span>
      <Swiper
        slidesPerGroup={1}
        slidesPerView="auto"
        spaceBetween={25}
        pagination={{ clickable: true }}
      >
        {
          imageData.links.map((linkData) => (
            <SwiperSlide key={linkData._key}>
              <Link className={headerLink} to={linkData.url}>
                <Image
                  className={headerImage}
                  image={linkData.linkImage}
                />
                {
                  false && (
                    <div className={headerDescription}>
                      <span className={headerText}>{linkData.title}</span>
                      <span className={headerTextIcon} />
                    </div>
                  )
                }
              </Link>
            </SwiperSlide>
          ))
        }
      </Swiper>
    </div>
  );
};

// Only Text Links Component
const TextLinks = ({ data }) => {
  const groupData = data.link.filter((dt) => dt._type === "groupLink");

  const Links = groupData.map((lnkGroup) => {
    const groupLinks = lnkGroup.links.map((lnk) => (<Link key={lnk._key} className={link} to={lnk.url}>{lnk.title}</Link>));
    return (
      <div key={lnkGroup._key} className={linkGroupWrapper}>
        <span className={linkGroup}>{lnkGroup.title}</span>
        { groupLinks }
      </div>
    );
  });

  return (
    <div className={textLinks}>
      { Links }
    </div>
  );
};

const HeaderBody = ({ headerData, socialData }) => {
  const { locale } = useContext(GlobalContext);
  const [selected, setSelected] = useState(locale?.toUpperCase());
  const menuData = headerData.hamburgerMenus;
  const headerLoc = getLoc()[locale].header;

  const languages = ["DE"];
  const langLabels = ("Custom Labels", { "DE": "Deutchland" });

  return (
    <div className={headerBodyWrapper}>
      <ImageLinks data={menuData} />
      <div className={linksWrapper}>
        <TextLinks data={menuData} />
        <div className={`${textLinks} ${contactLinks}`}>
          <div className={linkGroupWrapper}>
            <span className={linkGroup}>{headerLoc.contactTitle}</span>
            <a className={link} href={`tel:${socialData.phone}`}>
              <Telephone />
              { socialData.phone }
            </a>
            <a className={link} href={`mailto:${ socialData.email }`}>
              <Envelope />
              { socialData.email }
            </a>
            <div className={language}>
              <ReactFlagsSelect
                countries={languages}
                customLabels={langLabels}
                placeholder={headerLoc.language}
                selected={selected}
                onSelect={code => setSelected(code) }
              />
            </div>
          </div>
        </div>
      </div>
      <div className={socialIcons}>
        <Link to={ socialData.facebook } target="_blank" rel="noreferrer">
          <img src={facebookIcon}  alt="Facebook logo" />
        </Link>
        <Link to={ socialData.instagram } target="_blank" rel="noreferrer">
          <img src={instagramIcon}  alt="Instagram logo" />
        </Link>
        <Link to={ socialData.pinterest } target="_blank" rel="noreferrer">
          <img src={pinterestIcon} alt="Pinterest logo" />
        </Link>
      </div>
    </div>
  );
};

export default HeaderBody;
